import { Box } from '@mui/material';
export const SectionTitleTriangle = () => <Box sx={theme => ({
  width: 0,
  height: 0,
  borderStyle: 'solid',
  borderWidth: '0 0 54px 24px',
  borderColor: 'transparent',
  borderBottomColor: 'secondary.dark',
  position: 'absolute',
  top: 0,
  right: '100%',
  marginRight: '24px',
  boxShadow: theme => `24px 0 0 ${theme.palette.secondary.dark}`,
  [theme.breakpoints.down('lg')]: {
    display: 'none'
  }
})} data-sentry-element="Box" data-sentry-component="SectionTitleTriangle" data-sentry-source-file="SectionTitleTriangle.tsx" />;