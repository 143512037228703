'use client';

import ArrowBackIcon from '@mui/icons-material/ChevronLeft';
import ArrowForwardIcon from '@mui/icons-material/ChevronRight';
import { Button } from '@mui/material';
type UmbrellaCarouselArrowProps = {
  onClick?: () => void;
  variant: 'prev' | 'next';
};
export const UmbrellaCarouselArrow = ({
  onClick,
  variant
}: UmbrellaCarouselArrowProps) => <Button aria-label={variant === 'next' ? 'Next slide' : 'Previous slide'} onClick={onClick} color="secondary" sx={theme => ({
  width: 24,
  height: 24,
  position: 'absolute',
  top: '50%',
  transform: 'translate(0, -50%)',
  right: variant === 'next' ? -35 : 'auto',
  left: variant === 'prev' ? -35 : 'auto',
  p: 0,
  zIndex: theme.zIndex.mobileStepper,
  backgroundColor: 'transparent',
  '&:hover, &:focus': {
    textDecoration: 'underline'
  }
})} data-sentry-element="Button" data-sentry-component="UmbrellaCarouselArrow" data-sentry-source-file="UmbrellaCarouselArrow.tsx">
    {variant === 'next' ? <ArrowForwardIcon sx={{
    fontSize: 24
  }} /> : <ArrowBackIcon sx={{
    fontSize: 24
  }} />}
  </Button>;