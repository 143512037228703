import { SolutionSectionProps } from './SolutionSection';
import { ReactComponent as IconCrmIntentSignals } from './assets/icon-crm-intent-signals.svg';
import { ReactComponent as IconEcommerce } from './assets/icon-ecommerce.svg';
import { ReactComponent as IconMarketingAutomation } from './assets/icon-marketing-automation.svg';
import { ReactComponent as IconMarketplace } from './assets/icon-marketplace.svg';
import { ReactComponent as IconPIM } from './assets/icon-pim.svg';
import { ReactComponent as IconQuotationManagement } from './assets/icon-quotation-management.svg';
import { ReactComponent as IconSalesChannelSyndication } from './assets/icon-sales-channel-syndication.svg';
import imgCrmIntentSignals from './assets/img-crm-intent-signals.png';
import imgEcommerce from './assets/img-ecommerce.png';
import imgMarketingAutomation from './assets/img-marketing-automation.png';
import imgMarketplace from './assets/img-marketplace.png';
import imgPim from './assets/img-pim.png';
import imgQuotationManagement from './assets/img-quotation-management.png';
import imgSalesChannelSyndication from './assets/img-sales-channel-syndication.png';
export const solutionSectionData: SolutionSectionProps[] = [{
  icon: <IconPIM width={56} height={57} />,
  title: 'Product Information Management (PIM) Syndication',
  description: 'We take care of your product data—syncing, enriching, and constantly updating it from every manufacturer to keep your catalog precise and up-to-date. Missing a manufacturer? We’ll track them down and get their data for you.',
  href: '/solutions/pim-syndication',
  img: imgPim
}, {
  icon: <IconEcommerce width={56} height={57} />,
  title: 'E-Commerce',
  description: "Unlock your e-commerce potential with a shopping experience designed to keep customers coming back. Whether you're launching a new store or growing an existing one, we’ll have you live in just a few weeks!",
  href: '/solutions/e-commerce',
  img: imgEcommerce
}, {
  icon: <IconQuotationManagement width={56} height={57} />,
  title: 'Quotation Management',
  description: 'Make quoting easy with automated tracking, quick follow-ups, and smooth workflows that let you send quotes in min. Give your team the tools to close deals faster and spend more time building those customer relationships that count!',
  href: '/solutions/quotation-management',
  img: imgQuotationManagement
}, {
  icon: <IconMarketingAutomation width={56} height={57} />,
  title: 'Marketing Automation',
  description: 'Turn your marketing on autopilot with personalized, automated email campaigns that keep you connected to customers. Send timely offers and updates without lifting a finger—leaving you free to focus on what really matters.',
  href: '/solutions/marketing-automation',
  img: imgMarketingAutomation
}, {
  icon: <IconSalesChannelSyndication width={56} height={57} />,
  title: 'Sales Channel Syndication',
  description: 'Reach new customers wherever they are—from Google Merchant to top inventory listings. Open up fresh sales channels to showcase your products, move inventory, and bring in more traffic.',
  href: '/solutions/sales-channel-syndication',
  img: imgSalesChannelSyndication
}, {
  icon: <IconCrmIntentSignals width={56} height={57} />,
  title: 'CRM & Intent Signals',
  description: "Stay a step ahead with AI-powered insights that practically read your customers' minds! Get smart tips on when and how to reach out, so every interaction is spot-on and impactful. Who said staying ahead had to feel pushy?",
  href: '/solutions/crm-intent-signals',
  img: imgCrmIntentSignals
}, {
  icon: <IconMarketplace width={56} height={57} />,
  title: 'Get Discovered on the Quotebeam Marketplace',
  description: 'Put your products in front of ready-to-buy customers on Quotebeam’s Marketplace. Tap into high-intent leads with immediate needs to drive faster conversions and grow your sales.',
  href: '/solutions/marketplace',
  img: imgMarketplace
}];