import Image, { StaticImageData } from 'next/image';
import { Button, Typography, Unstable_Grid2 as Grid2 } from '@mui/material';
export type SolutionSectionProps = {
  icon?: React.ReactNode;
  title: string;
  description: string;
  href?: string;
  img: StaticImageData;
  isImgOnRight?: boolean;
};
export const SolutionSection = ({
  img,
  href,
  title,
  icon,
  description,
  isImgOnRight = false
}: SolutionSectionProps) => {
  return <Grid2 container columnSpacing={{
    sm: 1,
    md: 3
  }} direction={['row', isImgOnRight ? 'row' : 'row-reverse']} sx={{
    alignItems: 'center',
    py: [2.5, 4]
  }} data-sentry-element="Grid2" data-sentry-component="SolutionSection" data-sentry-source-file="SolutionSection.tsx">
      <Grid2 xs={12} sm={6} md={5} alignItems="center" mdOffset={isImgOnRight ? 0 : 1} data-sentry-element="Grid2" data-sentry-source-file="SolutionSection.tsx">
        {!!icon && icon}
        <Typography variant="h4" sx={{
        mb: 2,
        mt: [0.5, 1.25],
        textWrap: ['balance', 'unset']
      }} data-sentry-element="Typography" data-sentry-source-file="SolutionSection.tsx">
          {title}
        </Typography>
        <Typography variant="paragraph" sx={{
        mb: [1.5, 2.5]
      }} data-sentry-element="Typography" data-sentry-source-file="SolutionSection.tsx">
          {description}
        </Typography>
        {!!href && <Button href={href} variant="contained" color="primary" sx={{
        width: [150, 208]
      }}>
            Learn More
          </Button>}
      </Grid2>
      <Grid2 xs={12} sm={6} md={6} mdOffset={isImgOnRight ? 1 : 0} data-sentry-element="Grid2" data-sentry-source-file="SolutionSection.tsx">
        <Image loading="eager" src={img.src} width={img.width} height={img.height} alt={title} sizes="(max-width: 564px) 100vw, 564px" style={{
        width: '100%',
        height: 'auto'
      }} data-sentry-element="Image" data-sentry-source-file="SolutionSection.tsx" />
      </Grid2>
    </Grid2>;
};