'use client';

import { Container } from '@mui/material';
import { GetStartedSteps } from './_components/GetStartedSteps/GetStartedSteps';
import { SolutionSectionsTitle } from './_components/GetStartedSteps/SolutionSectionsTitle';
import { QuotebeamImpactSection } from './_components/QuotebeamImpactSection/QuotebeamImpactSection';
import { SolutionSection } from './_components/SolutionSection/SolutionSection';
import { solutionSectionData } from './_components/SolutionSection/solutionSection.data';
import { UmbrellaIntro } from './_components/UmbrellaIntro/UmbrellaIntro';
import { YourSecretWeaponSection } from './_components/YourSecretWeaponSection/YourSecretWeaponSection';
import { QuotebeamImpactSectionGradient } from './_components/_components/QuotebeamImpactSectionGradient';
export default function UmbrellaPage() {
  return <>
      <UmbrellaIntro data-sentry-element="UmbrellaIntro" data-sentry-source-file="page.tsx" />
      <YourSecretWeaponSection data-sentry-element="YourSecretWeaponSection" data-sentry-source-file="page.tsx" />
      <QuotebeamImpactSection data-sentry-element="QuotebeamImpactSection" data-sentry-source-file="page.tsx" />
      <QuotebeamImpactSectionGradient data-sentry-element="QuotebeamImpactSectionGradient" data-sentry-source-file="page.tsx" />
      <Container data-sentry-element="Container" data-sentry-source-file="page.tsx">
        <SolutionSectionsTitle data-sentry-element="SolutionSectionsTitle" data-sentry-source-file="page.tsx" />
        {solutionSectionData.map((section, index) => <SolutionSection key={section.title} isImgOnRight={index % 2 === 0} {...section} />)}
      </Container>
      <GetStartedSteps data-sentry-element="GetStartedSteps" data-sentry-source-file="page.tsx" />
    </>;
}