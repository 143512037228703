import { getImageProps } from 'next/image';
import { Box, Typography, Stack } from '@mui/material';
import { UmbrellaButton } from '@/app/(index)/_components/_components/UmbrellaButton';
import { UmbrellaIntroCarousel } from './UmbrellaIntroCarousel';
import introBg from './assets/img-intro-bg.png';
export const UmbrellaIntro = () => {
  const {
    props: {
      src: srcDesktop
    }
  } = getImageProps({
    src: introBg.src,
    fill: true,
    alt: 'bg',
    priority: true
  });
  return <Box sx={{
    mx: [1, 2],
    mb: [1, 3]
  }} data-sentry-element="Box" data-sentry-component="UmbrellaIntro" data-sentry-source-file="UmbrellaIntro.tsx">
      <Box sx={{
      width: '100%',
      borderRadius: '26px',
      background: '#fff 0px 0px / 100% 100% no-repeat',
      backgroundImage: `url(${srcDesktop})`,
      pt: {
        xs: 5.5,
        md: 10,
        lg: 18.25
      }
    }} data-sentry-element="Box" data-sentry-source-file="UmbrellaIntro.tsx">
        <Typography variant="h1" align="center" sx={theme => ({
        mb: [4, 7],
        lineHeight: 1.2,
        [theme.breakpoints.down('lg')]: {
          fontSize: 24
        }
      })} data-sentry-element="Typography" data-sentry-source-file="UmbrellaIntro.tsx">
          Your all-in-one
          <br />
          customer engagement platform
        </Typography>
        <Typography variant="h3" align="center" sx={[{
        mb: 2
      }, theme => ({
        mb: [0, 2],
        px: [5, 0],
        [theme.breakpoints.down('lg')]: {
          fontSize: 18
        }
      })]} data-sentry-element="Typography" data-sentry-source-file="UmbrellaIntro.tsx">
          How Do Distributors Use Quotebeam?
        </Typography>
        <UmbrellaIntroCarousel data-sentry-element="UmbrellaIntroCarousel" data-sentry-source-file="UmbrellaIntro.tsx" />
        <Stack sx={{
        alignItems: 'center',
        pt: [5, 7.5],
        pb: [5, 10.5]
      }} data-sentry-element="Stack" data-sentry-source-file="UmbrellaIntro.tsx">
          <UmbrellaButton href="/get-a-demo" sx={{
          width: 142
        }} variant="contained" data-sentry-element="UmbrellaButton" data-sentry-source-file="UmbrellaIntro.tsx">
            Talk to Sales
          </UmbrellaButton>
        </Stack>
      </Box>
    </Box>;
};