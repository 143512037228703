import Image from 'next/image';
import { Container, Grid, Typography, Box } from '@mui/material';
import imgYourSecretWeaponSectionMobile from './assets/img-YourSecretWeaponSection-mobile.jpeg';
import imgYourSecretWeaponSection from './assets/img-YourSecretWeaponSection.png';
const GradiantContainer = () => <Grid data-cy="gradiant-container" xs={12} md={8} sx={{
  display: ['none', 'block'],
  position: 'absolute',
  top: 0,
  bottom: 0,
  width: '100%',
  zIndex: 1,
  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: '#fff linear-gradient(270deg, #E6E2FA 15.5%, rgba(247, 237, 255, 0.00) 100%)'
  }
}} data-sentry-element="Grid" data-sentry-component="GradiantContainer" data-sentry-source-file="YourSecretWeaponSection.tsx" />;
const ImageContainer = () => {
  const height = 530;
  return <Box data-cy="image-container" sx={{
    display: ['none', 'block'],
    position: 'absolute',
    top: `calc(50% - ${height / 2}px)`,
    bottom: 0,
    right: 0,
    height,
    width: '50%'
  }} data-sentry-element="Box" data-sentry-component="ImageContainer" data-sentry-source-file="YourSecretWeaponSection.tsx">
      <Image loading="eager" src={imgYourSecretWeaponSection.src} width={imgYourSecretWeaponSection.width} height={imgYourSecretWeaponSection.height} alt="Quotebeam: Your Secret Weapon to Customer Loyalty" sizes="(max-width: 690px) 100vw, 690px" style={{
      width: 'auto',
      height: '100%'
    }} data-sentry-element="Image" data-sentry-source-file="YourSecretWeaponSection.tsx" />
    </Box>;
};
const ImageContainerMobile = () => {
  return <Box data-cy="image-mobile-container" sx={{
    display: ['flex', 'none']
  }} data-sentry-element="Box" data-sentry-component="ImageContainerMobile" data-sentry-source-file="YourSecretWeaponSection.tsx">
      <Image loading="eager" src={imgYourSecretWeaponSectionMobile.src} width={imgYourSecretWeaponSectionMobile.width} height={imgYourSecretWeaponSectionMobile.height} alt="Quotebeam: Your Secret Weapon to Customer Loyalty" sizes="(max-width: 600px) 100vw, 600px" style={{
      width: '100%',
      height: 'auto'
    }} data-sentry-element="Image" data-sentry-source-file="YourSecretWeaponSection.tsx" />
    </Box>;
};
export const YourSecretWeaponSection = () => {
  return <Box sx={{
    mt: [1, 2.5],
    background: 'linear-gradient(270deg, #E6E2FA 15.5%, rgba(247, 237, 255, 0.00) 100%)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: ['100% 100%', '100% 120px']
  }} data-sentry-element="Box" data-sentry-component="YourSecretWeaponSection" data-sentry-source-file="YourSecretWeaponSection.tsx">
      <Container sx={{
      py: [6, 25],
      overflow: 'hidden'
    }} data-sentry-element="Container" data-sentry-source-file="YourSecretWeaponSection.tsx">
        <Grid container sx={{
        position: 'relative'
      }} data-sentry-element="Grid" data-sentry-source-file="YourSecretWeaponSection.tsx">
          <GradiantContainer data-sentry-element="GradiantContainer" data-sentry-source-file="YourSecretWeaponSection.tsx" />
          <ImageContainer data-sentry-element="ImageContainer" data-sentry-source-file="YourSecretWeaponSection.tsx" />
          <Grid data-cy="text-container" xs={12} md={7} sx={{
          py: [0, 3.625],
          position: 'relative',
          zIndex: 2
        }} data-sentry-element="Grid" data-sentry-source-file="YourSecretWeaponSection.tsx">
            <Typography variant="h3" sx={theme => ({
            mb: 3,
            [theme.breakpoints.down('lg')]: {
              fontSize: 18
            }
          })} data-sentry-element="Typography" data-sentry-source-file="YourSecretWeaponSection.tsx">
              Quotebeam: Your Secret Weapon to <br />
              Customer Loyalty
            </Typography>
            <Typography variant="paragraph" sx={{
            fontSize: 16
          }} data-sentry-element="Typography" data-sentry-source-file="YourSecretWeaponSection.tsx">
              Quotebeam lets you zero in on what really matters: wowing your
              customers. Our platform takes the pain out of repetitive tasks
              like quoting, order processing, and product data management—so
              your team can prioritize customer needs, offer personalized
              service, and grow long-term relationships. With us on your side,
              you’ll have more time to build connections that last.
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <ImageContainerMobile data-sentry-element="ImageContainerMobile" data-sentry-source-file="YourSecretWeaponSection.tsx" />
    </Box>;
};